import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/hero"
import styled from "styled-components"
import Button from "react-bootstrap/Button"
import { HeaderStyle } from "../components/styles/main"

const ContentWrapper = styled.div`
  padding: 1rem 2rem;
`
export default function Template({ data }) {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Hero src={post.frontmatter.header} alt="Header for Post" />
      <ContentWrapper>
        <Link to="/contact" state={{ title: post.frontmatter.title }}>
          <Button style={{ float: `right` }} variant="secondary">
            Contact Us
          </Button>
        </Link>
        <HeaderStyle>{post.frontmatter.title}</HeaderStyle>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </ContentWrapper>
    </Layout>
  )
}

export const postQuery = graphql`
  query ServicesByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        header
        path
        title
      }
    }
  }
`
